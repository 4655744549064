body {
  .edit-save-search-tab-indicator .MuiTabs-indicator {
    display: none;
  }
  .filter-jobs .MuiSwitch-track {
    background: #eff0f4;
  }
  .edit-filter .MuiSwitch-track {
    background: #eff0f4;
  }
  .filter-jobs button {
    border-radius: 22px;
  }
  .edit-filter .Mui-checked .MuiSwitch-track {
    background: #abacc3;
  }
  .edit-filter .MuiSlider-root {
    color: #abacc3;
  }
  .edit-filter .field-dropdown {
    background: #abacc3;
    border: 1px solid #abacc3;
  }

  .edit-filter button {
    color: #fff;
    border: 1px solid #abacc3;
    background: #abacc3;
    border-radius: 22px;
  }

  .edit-filter button:hover {
    border: 1px solid #9d9fc0 !important;
    background: #9d9fc0 !important;
    color: #fff !important;
  }

  .save-search-title-field {
    padding-left: 21px;
    padding-right: 21px;
  }

  .save-search-title-field input {
    height: 4px;
  }
  .save-search-title-field > div {
    border-radius: 13px;
    padding-left: 15px;
    color: #b7b7b7;
    font-weight: 600;
  }

  .save-search-title-field:hover fieldset {
    border-color: #000000;
  }

  .save-search-title-field .Mui-focused fieldset {
    border-color: #000000 !important;
  }

  .update-search-title-field input {
    height: 0px;
  }
  .update-search-title-field > div {
    border-radius: 10px;
    padding-left: 15px;
    color: #b7b7b7;
    font-weight: 600;
  }
  .update-search-title-field:hover fieldset {
    border-color: #000000;
  }
  .update-search-title-field .Mui-focused fieldset {
    border-color: #000000 !important;
  }
  .onboarding-company-profile input {
    padding-left: 35px;
    color: #000000;
  }
  .onboard-city input {
    padding-left: 22px !important;
  }
  .onboard-state input {
    padding-left: 22px !important;
  }
  .onboard-state > div > div {
    padding-right: 30% !important;
  }
  .onboard-state .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .onboard-city > div > div {
    padding-right: 30% !important;
  }
  .onboard-city .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .onboard-state .MuiAutocomplete-popupIndicator,
  .onboard-city .MuiAutocomplete-popupIndicator {
    margin-right: 10px;
  }

  .onboard-state svg,
  .onboard-city svg,
  .multiple-cat-dropdown svg {
    color: #a5a5a5;
  }

  .onboaring-driver-form input {
    padding-left: 35px !important;
  }
  .post-job-form input,
  textarea {
    padding-left: 35px !important;
    color: #6b6b6b !important;
  }

  .settings-form input {
    padding-left: 25px !important;
    color: #6b6b6b !important;
    border-radius: 25px !important;
  }

  .settings-form textarea {
    border-radius: 25px !important;
  }

  .settings-form textarea {
    padding-left: 25px !important;
  }

  .settings-form .MuiSelect-select.MuiSelect-selectMenu {
    border-radius: 25px !important;
  }

  .search-carrier-field input {
    height: 12px !important;
  }

  .input-dropbox > div > div {
    display: flex;
    align-items: center;
    padding-left: 35px;
    color: #6b6b6b;
  }
  .settings-form .input-dropbox > div > div {
    padding-left: 25px;
  }
  .mc-field input {
    padding-left: unset !important;
  }
  .step-content-main {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .step-content-container {
    width: 45%;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .markers-section img {
    max-width: 100%;
  }

  .onboarding-content {
    height: 90vh;
    overflow-y: auto;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }
  @media only screen and (max-width: 768px) {
    .onboarding-content {
      border-radius:0;
    }
  }
  @media only screen and (max-width: 768px) {
    .step-content-container {
      width: 80%;
      height:100vh;
    }

    .onboarding-content {
      height: 100%;
    }
  }
}
