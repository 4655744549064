body {
  input,
  label {
    font-family: poppins !important;
  }
  .filter-dimension-field input {
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-top-left-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    height: 0 !important;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .filter-jobs .MuiSlider-markLabel,
  .edit-filter .MuiSlider-markLabel {
    color: #a3a3a3;
    font-family: Poppins;
    font-size: 12px;
  }

  .filter-jobs .MuiSlider-track {
    color: #dd8074;
  }
  .filter-jobs .single-thumb .MuiSlider-track,
  .edit-filter .single-thumb .MuiSlider-track {
    left: -10px !important;
  }

  .filter-jobs .filter-mark-label[data-index='1'],
  .edit-filter .filter-mark-label[data-index='1'] {
    left: 99% !important;
  }

  .edit-filter .MuiSlider-track {
    color: #dddddd;
  }
  .filter-jobs .MuiSlider-rail {
    color: #dadce1;
  }

  .filter-jobs .MuiSlider-rail,
  .edit-filter .MuiSlider-rail {
    left: -10px;
    padding-right: 20px;
  }

  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-thumb {
    box-shadow: none !important;
  }
  .MuiSlider-valueLabel {
    max-width: 40px;
    max-height: 21px;
  }
  .MuiSlider-valueLabelLabel {
    font-size: 8px;
    color: #000000;
  }
  .MuiSlider-valueLabelCircle {
    display: flex;
  }
  #mui-component-select-shipper_type {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }
  .trailer-type-pref > div > div {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }
  .carrier-loads-map > div {
    border-radius: 20px;
  }
  .onboarding-select-vehicle label {
    padding-left: 35px;
  }
  .signin-signup-layout input {
    padding-left: 25px;
  }
  .verify-email-form input {
    padding-left: unset;
    font-family: 'Poppins';
  }
  .table-container tr td:first-child {
    padding-left: 35px;
  }
  .loads-table tr td:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  .loads-table tr td:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
  .loads-table tr:first-child td:first-child {
    border-top-left-radius: 0px;
  }
  .loads-table tr:first-child td:last-child {
    border-top-right-radius: 0px;
  }
  .loads-table tr td {
    border-bottom: 10px solid #f5f8fa !important;
  }

  .loads-table table {
    border: none;
  }

  .fixedfilers {
    top: 49px;
    position: sticky;
  }
  
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .fixedfilers {
        top: 0;
      }
    }
  }

  .table-container tr th:first-child {
    padding-left: 35px;
  }
  .input-box > div {
    border-radius: 16px;
    background: $white;
    //box-shadow: 0px 2px 18px -5px rgba(0, 0, 0, 0.75);
  }

  .performance-dropdown::before {
    content: none !important;
  }

  .performance-dropdown::after {
    content: none !important;
  }

  .performance-dropdown > div {
    color: #c7c7c7 !important;
    font-size: 13px;
    font-family: 'Poppins';
  }

  .profile-menu-main .MuiPaper-root {
    border-radius: 8px;
  }

  .load-actions .MuiPaper-root {
    border-radius: 8px;
  }

  .profile-menu-item:hover {
    background-color: #f8f8f8;
  }

  .filter-input-box input::placeholder {
    font-weight: 600;
    font-size: 12px;
  }

  .filter-input-box2 input::placeholder {
    font-weight: 600!important;
    font-size: 14px!important;
  }

  .filter-input-box input {
    padding-left: 35px;
    border-radius: 12px !important;
    height: 0px !important;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12px;
    font-weight: 600;
  }
  .filter-input-field input {
    height: 0px !important;
    font-size: 12px;
    border-radius: 11px !important;
  }
  .input-box fieldset {
    border: none;
  }

  .input-box .Mui-error fieldset {
    border: 1px solid #ccc;
  }

  .input-box input,
  textarea,
  .MuiSelect-root {
    height: 8px;
    border-radius: 16px;
    box-shadow: 0px 0px 15px #00000033;
  }

  .input-box.end-endorment input {
    box-shadow: none;
  }

  .input-box.end-endorment > div {
    box-shadow: 0px 0px 15px #00000033;
  }

  .input-box label {
    top: -4px !important;
  }

  .input-box label[data-shrink='true'] {
    top: -10px !important;
    color: #4b4c4d;
    font-weight: $font-weight-bold;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 18.5px 14px;
  }

  .input-box-dropdown label {
    top: 0 !important;
  }

  .input-box-effect input {
    box-shadow: none;
    border: 3px solid #ebebeb;
  }

  .input-box2 > div {
    border-radius: 16px;
    background: $white;
    box-shadow: 0px 0px 15px #00000033;
  }

  .input-box2 fieldset {
    border: none;
  }

  .input-box2 .Mui-error fieldset {
    border: 1px solid #ccc;
  }

  .input-box2 input,
  .MuiSelect-root {
    height: 8px;
  }

  .input-box2 label {
    top: -4px !important;
  }

  .input-box2 label[data-shrink='true'] {
    top: -10px !important;
    color: #4b4c4d;
    font-weight: $font-weight-bold;
  }

  .input-box-mb0 > div {
    margin-bottom: 0;
  }

  .bid-date > div::before {
    border-bottom: 1px solid #d0d0d0 !important;
  }

  .bid-date > div::after {
    border-bottom: 2px solid #212122 !important;
  }

  #mui-component-select-vehicle_type {
    height: auto;
  }

  .submit-button {
    width: 100%;
    background: $red;
    border-radius: 16px;
    margin-top: 20px;
  }

  .head-title {
    color: $red;
    font-weight: $font-weight-light;
  }

  .container {
    padding: 2rem 2rem 1rem;
  }

  .post-job-date-time button {
    padding: 0;
  }

  .distance-field input {
    color: $black;
  }

  .field-dropdown.dimension > div {
    height: 0;
    font-size: 9px;
    padding-left: 3px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
  }

  .field-dropdown.dimension {
    width: 35px;
  }

  .field-dropdown {
    background: $red;
    border: 1px solid $red;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $white;
    position: absolute;
    right: 0;
    width: 50px;
  }

  .field-dropdown > div {
    display: flex;
    align-items: center;
    padding-right: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 12px;
  }

  .field-dropdown-start {
    background: $red;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    color: $white;
    margin-left: -16px;
  }

  .field-dropdown-start > div {
    padding-right: 24px !important;
  }

  .field-dropdown svg {
    color: $white;
    right: 0;
  }

  .multiple-cat-dropdown svg {
    top: 20%;
  }

  .multiple-cat-dropdown-err > div {
    border: 1px solid red;
  }

  .MuiTabs-indicator {
    background: #abacc3 !important;
    height: 3px;
  }

  .filter-head .MuiButtonBase-root {
    color: #d3d3d3;
    font-weight: 600;
    text-transform: capitalize !important;
  }

  .filter-head.tabindex_0 .MuiTabs-indicator {
    width: 38px !important;
  }

  .filter-head.tabindex_1 .MuiTabs-indicator {
    width: 120px !important;
  }

  .single-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #multiple-cat-dropdown-label {
    position: absolute;
    z-index: 1;
    left: 35px;
    top: 12px;
    color: #a5a5a5;
    font-size: 15px;
  }

  .multiple-cat-dropdown svg {
    right: 20px;
  }

  .field-dropdown-start svg {
    color: $white;
  }

  .with-adornment fieldset {
    border: none;
  }

  .MuiSlider-valueLabel {
    background-color: $white;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .navigate-absolute {
    position: absolute;
    right: 0;
    top: 0;
  }

  .onboard-my-vehicles {
    padding: 30px !important;
  }

  .onboard-my-vehicles-block {
    padding: 0px 20px 20px !important;
  }

  .onboard-welcome-block {
    padding: 50px !important;
  }

  @media only screen and (max-width: 720px) {
    .onboard-welcome-block {
      padding: 0 !important;
    }
    .onboard-my-vehicles {
      padding: 0px !important;
    }
    .onboard-my-vehicles-block {
      padding: 0 !important;
    }
    .navigate-absolute > p {
      position: absolute;
      right: 12px;
      top: 10px;
      font-size: 12px !important;
    }
  }

  .MuiSlider-valueLabel::before {
    border-bottom-color: #ccc;
    border-width: 1px;
    border-style: solid;
    border-right-color: #ccc;
    border-top-color: #fff;
    border-left-color: #fff;
    background: #fff;
  }

  .app-sidebar-logo.loader {
    -webkit-animation: rotation 2s infinite linear;
    animation: rotation 2s infinite linear;
  }

  .line-break-anywhere {
    overflow-wrap: anywhere;
  }

  .form-post-truck input {
    padding-left: 35px;
  }

  .form-post-truck label {
    padding-left: 35px;
  }

  .MuiSelect-select:focus {
    border-radius: 16px;
    background-color: unset;
  }

  .reference-field input {
    background: #efefef;
  }

  #cancel-load-input-label {
    position: absolute;
    z-index: 1;
    padding-left: 30px;
    top: -6px;
    font-size: 12px;
  }

  .carrier-loads-map .gm-style div:nth-of-type(2) {
    // transition: width 0.5s;
    border-radius: 20px;
    //overflow: hidden;
  }

  .carrier-loads-map .gm-style {
    border-radius: 20px;
    overflow: hidden;
  }

  .rotate_map_expand_icon {
    transition: transform 0.4s;
  }

  .rotate_icon_180deg {
    transform: rotate(180deg);
  }

  .my-location-icon {
    position: absolute;
    right: 17px;
    bottom: 120px;
    cursor: pointer;
  }

  .my-location-icon-loader {
    position: absolute;
    color: #e53835;
    right: 22px;
    bottom: 120px;
  }

  button {
    font-family: Poppins !important;
  }

  .add-driver-field input {
    font-size: 12px;
    color: #939393;
    padding-left: 20px;
  }

  #mui-component-select-truck_type {
    display: flex;
    align-items: center;
    color: #969696;
    font-size: 12px;
    padding-left: 25px;
  }

  .autorotatingcarousel-bol-arrow-left {
    left: -26px;
  }
  .autorotatingcarousel-bol-arrow-right {
    right: -26px;
  }
  .autorotatingcarousel-bol-footer {
    display: none;
  }

  // Loads as price icon on map
  .carrier-loads-map .price-marker-label {
    font-family: Poppins !important;
    font-weight: 700;
    font-size: 12px !important;
    //background: #fff;
  }

  // .carrier-loads-map .price-marker-label.marker-hovered {
  //   background: #f0f0f0;
  // }

  // .carrier-loads-map .price-marker-label.marker-active {
  //   background: #2f2f2f;
  // }

  // Zoom control
  .carrier-loads-map .gmnoprint:nth-of-type(2) > div {
    border-radius: 11px !important;
    width: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carrier-loads-map .gmnoprint:nth-of-type(2) > div button img {
    width: 10px !important;
    height: 10px !important;
  }

  .job-paid-date input {
    padding-left: 35px;
    color: #6b6b6b;
  }

  .shipment-fade {
    position: relative;
    width: 300px;
    float: right;
  }

  .shipment-fade::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0;
    left: 7px;
    right: 7px;

    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 18%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0.91) 49%,
      rgba(229, 229, 229, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 18%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0.91) 49%,
      rgba(229, 229, 229, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 18%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0.91) 49%,
      rgba(229, 229, 229, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00e5e5e5',GradientType=0 );
  }

  .shipment-fade::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 7px;
    right: 7px;
    z-index: 1;

    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 84%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 84%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 84%,
      rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  }

  .dropdown-open > div {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .dropdown-flexed > div {
    display: flex;
    align-items: center;
    padding-left: 35px;
  }

  .input-box.Mui-error > .MuiSelect-select {
    border: 1px solid red;
  }

  .verification-docs-tabs > div {
    overflow-x: auto !important;
    display: flex;
    justify-content: center;
  }

  .verification-docs-tabs .MuiTabs-indicator {
    background-color: #ee3c2d !important;
  }

  .account-verificaion-view-visible .verification-docs-tabs .MuiTabs-indicator {
    display: none;
  }

  .capitalizefield input {
    text-transform: capitalize;
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @media only screen and (max-width: 1280px) {
    .markers-section {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .stppr {
      display: none;
    }
  }
}


.invoice-table tr td {
  border-top: 2px solid #E6E6E6;
  border-bottom: 2px solid #E6E6E6;
}
.invoice-table tr {
  justify-content: left;
}
.invoice-table tr:first-child {
  border-top: 0;
}
.invoice-table tr:first-child td:first-child {
  border-top-left-radius: 0;
  border-top: 0;
}
.invoice-table tr:first-child td:last-child {
  border-top-right-radius: 0;
}
.invoice-table tr td:last-child {
  width: 40px; 
}

.invoice-table tr td:first-child {
  border-left: 2px solid #E6E6E6;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.invoice-table tr td:last-child {
  border-right: 2px solid #E6E6E6;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.invoice-table table {
  border: none;
}

.invoice-table tr:first-child td {
  border-top: none;
}
.fixedfiler-invoice {
  top: 58px;
  position: sticky;
}